import {
  AspectLabels,
  AspectState,
  AspectStructure,
  SET_ASPECTS,
  SET_ASPECTS_LABELS,
  SET_SELECTED_ASPECT_PAIR,
  SelectedAspectPair,
} from '../types';
import { EMPTY_ASPECT_PAIR } from '../utils';

type AspectLabelsAction = {
  type: typeof SET_ASPECTS_LABELS;
  data: AspectLabels;
};

type AspectsAction = {
  type: typeof SET_ASPECTS;
  data: AspectStructure;
};

interface SelectedAspectPairAction {
  type: typeof SET_SELECTED_ASPECT_PAIR;
  data: SelectedAspectPair;
}

type Action = AspectLabelsAction | AspectsAction | SelectedAspectPairAction;

const initialState: AspectState = {
  aspects: [],
  structure: [],
  defaultPair: '',
  aspectLabels: undefined,
  selectedAspectPair: EMPTY_ASPECT_PAIR,
};

const reducer = (state = initialState, action: Action): AspectState => {
  switch (action.type) {
    case SET_ASPECTS:
      return {
        ...state,
        ...action.data,
      };
    case SET_ASPECTS_LABELS:
      return { ...state, aspectLabels: action.data };
    case SET_SELECTED_ASPECT_PAIR:
      return { ...state, selectedAspectPair: action.data ?? EMPTY_ASPECT_PAIR };
    default:
      return state;
  }
};

export default reducer;
