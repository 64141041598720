import { AspectLabels, AspectStructure, SelectedAspectPair } from './types';

export const SET_ASPECTS = 'SET_ASPECTS';
export const SET_ASPECTS_LABELS = 'SET_ASPECTS_LABELS';
export const SET_SELECTED_ASPECT_PAIR = 'SET_SELECTED_ASPECT_PAIR';

export interface AspectState extends AspectStructure {
  aspectLabels?: AspectLabels;
  selectedAspectPair: SelectedAspectPair;
}
