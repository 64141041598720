import { ModelState } from '../types/model';
import { SET_SELECTED_ASPECT_NOTE } from '../types/note';

interface SelectedNotesAction {
  type: typeof SET_SELECTED_ASPECT_NOTE;
  data?: ModelState['selectedNote'];
}

type ModelAction = SelectedNotesAction;

const initialState: ModelState = {
  isFetching: false,
};

const Model = (state = initialState, action: ModelAction): ModelState => {
  switch (action.type) {
    case SET_SELECTED_ASPECT_NOTE:
      return {
        ...state,
        selectedNote: action.data,
      };
    default:
      return state;
  }
};

export default Model;
