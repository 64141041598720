import { Actions } from '../../Ecosystem/types/ecosystem';
import {
  FETCH_TOP_STRATEGY_SCENARIO,
  SCENARIO_DETAILS_BY_ID,
  SET_SCENARIO_CANVAS_DATA,
  SET_SCENARIO_CANVAS_LOADING,
  SET_SCENARIO_LIST_COUNT,
  SET_SCENARIO_STRATEGY_MAP,
  ScenarioState,
  UPDATE_SCENARIO_CANVAS_LIST,
} from '../types/scenario';
import { SET_SCENARIO_FILTER_TAGS, UPDATE_SCENARIO_FILTER_TAGS } from '../types/scenarioTags';

const initialState: ScenarioState = {
  isFetching: false,
  scenarioListCount: 0,
  strategyList: [],
  scenarioCanvasList: { canvasData: [], loading: false },
  scenarioStrategyMapList: [],
  scenarioFilterTags: [],
  scenarioDetailsById: {},
};
const Scenario = (state = initialState, actions: Actions): ScenarioState => {
  switch (actions.type) {
    case FETCH_TOP_STRATEGY_SCENARIO:
      return {
        ...state,
        strategyList: actions.data,
      };
    case SET_SCENARIO_CANVAS_DATA:
      return {
        ...state,
        scenarioCanvasList: {
          canvasData: [...state.scenarioCanvasList.canvasData, ...actions.data],
          loading: state.scenarioCanvasList.loading,
        },
      };
    case SET_SCENARIO_CANVAS_LOADING:
      return {
        ...state,
        scenarioCanvasList: {
          canvasData: state.scenarioCanvasList.canvasData,
          loading: actions.data,
        },
      };
    case UPDATE_SCENARIO_CANVAS_LIST:
      return {
        ...state,
        scenarioCanvasList: {
          canvasData: actions.data,
          loading: state.scenarioCanvasList.loading,
        },
      };
    case SET_SCENARIO_STRATEGY_MAP:
      return {
        ...state,
        scenarioStrategyMapList: actions.data,
      };
    case SET_SCENARIO_FILTER_TAGS:
      return {
        ...state,
        scenarioFilterTags: [...state.scenarioFilterTags, actions.data],
      };
    case UPDATE_SCENARIO_FILTER_TAGS:
      return {
        ...state,
        scenarioFilterTags: actions.data,
      };
    case SCENARIO_DETAILS_BY_ID:
      return {
        ...state,
        scenarioDetailsById: actions.data,
      };
    case SET_SCENARIO_LIST_COUNT:
      return {
        ...state,
        scenarioListCount: actions.data,
      };
    default:
      return state;
  }
};
export default Scenario;
